export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Dashboard',
        to: '/dashboard',
        icon: 'cilChartPie',
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Administration']
      },

      {
        _name: 'CSidebarNavItem',
        name: 'Applications',
        to: '/admins/ApplicationList',
        icon: 'cilSpreadsheet'
      },
    ]
  }
]