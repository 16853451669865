<template>
  <CSidebar
    :minimize="minimize"
    unfoldable
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand to="/">
      <img
        src="/img/side.png"
        :height="40"
        size="custom-size"
        class="c-sidebar-brand-full"
      />
    </CSidebarBrand>
    <!-- <CSidebarBrand class="d-md-down-none" to="/">
      <CIcon
        class="c-sidebar-brand-full"
        name="logo"
        size="custom-size"
        :height="35"
        viewBox="0 0 642 134"
      />
      <CIcon
        class="c-sidebar-brand-minimized"
        name="logo"
        size="custom-size"
        :height="35"
        viewBox="0 0 110 134"
      />
    </CSidebarBrand> -->
    <CRenderFunction flat :contentToRender="menu" />
    <CSidebarMinimizer
      class="c-d-md-down-none"
      @click.native="$store.commit('toggle', 'sidebarMinimize')"
    />
  </CSidebar>
</template>

<script>
import adminNav from "./_adminNav";
import operatorNav from "./_operatorNav";
import agentNav from "./_agentNav";
import { mapGetters } from 'vuex'

export default {
  name: "TheSidebar",
  adminNav,
  operatorNav,
  agentNav,
  computed: {
    ...mapGetters(['menu', 'isAuthenticated', 'roles']),
    show() {
      return this.$store.state.sidebarShow;
    },
    minimize() {
      return this.$store.state.sidebarMinimize;
    },
  },
};
</script>

