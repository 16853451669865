<template>
  <CFooter :fixed="false">
    <div>
      <a href="https://cartradepro.safa.com.my" target="_blank">CarTrade Pro</a>
      <span class="ml-1">&copy; {{new Date().getFullYear()}} creativeLabs. {{ version }}</span>
    </div>
    <div class="mfs-auto">
      <span class="mr-1" target="_blank">Powered by</span>
      <a href="https://cartradepro.safa.com.my">Car Trade Pro</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter',
  computed: {
    version() {
      return window.config.VERSION;
    },
  },
}
</script>
