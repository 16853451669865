export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Dashboard',
        to: '/dashboard',
        icon: 'cilChartPie',
        // badge: {
        //   color: 'primary',
        //   text: 'NEW'
        // }
      },
      
      {
        _name: 'CSidebarNavTitle',
        _children: ['Order']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'New Application',
        to: '/agents/Application',
        icon: 'cilUser'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Applications',
        to: '/agents/ApplicationList',
        icon: 'cilUser'
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['SubAgent']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Agents',
        to: '/agents/SubAgentList',
        icon: 'cilUser'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Commisions',
        to: '/agents/SubAgentCommisionList',
        icon: 'cilUser'
      },
      // {
      //   _name: 'CSidebarNavTitle',
      //   _children: ['Withdraw']
      // },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Pending Submission',
      //   to: '/agents/Application',
      //   icon: 'cilUser'
      // },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Withdraw List',
      //   to: '/agents/ApplicationList',
      //   icon: 'cilUser'
      // },
    ]
  }
]