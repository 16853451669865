<template>
  <CHeader with-subheader>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />
    <CHeaderBrand class="mx-auto d-lg-none" to="/">
      <!-- <CIcon name="logo" height="48" alt="Logo"/> -->
      <img
        src="/img/header.png"
        :height="40"
        size="custom-size"
        class="c-sidebar-brand-full"
      />
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto">
     
    </CHeaderNav>
    <CHeaderNav>
      {{ userName }}
    
      <TheHeaderDropdownAccnt />
    
    </CHeaderNav>

  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from "./TheHeaderDropdownAccnt";
import TheHeaderDropdownNotif from "./TheHeaderDropdownNotif";
import TheHeaderDropdownTasks from "./TheHeaderDropdownTasks";
import TheHeaderDropdownMssgs from "./TheHeaderDropdownMssgs";

export default {
  name: "TheHeader",
  components: {
    TheHeaderDropdownAccnt,
    TheHeaderDropdownNotif,
    TheHeaderDropdownTasks,
    TheHeaderDropdownMssgs,
  },
  computed: {
    userName() {
      try {
        return auth.getUserName();
      } catch (error) {}
      return "Unknown";
    },
  },
};
</script>
