<template>
  <div class="c-app" :class="{ 'c-dark-theme': $store.state.darkMode }">
    <TheSidebar/>
    <TheAside/>
    <CWrapper>
      <TheHeader/>
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path"></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
      <TheFooter/>
    </CWrapper>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TheSidebar from './TheSidebar'
import TheHeader from './TheHeader'
import TheFooter from './TheFooter'
import TheAside from './TheAside'

export default {
  name: 'TheContainer',
  components: {
    TheSidebar,
    TheHeader,
    TheFooter,
    TheAside
  },
  // computed: {
  //   ...mapState(['menu', 'roles', 'permissions', 'isAuthenticated'])
  // },
  // watch: {
  //   roles(newRoles) {
  //     if (this.isAuthenticated) {
  //       this.updateMenu(newRoles, this.permissions)
  //     }
  //   },
  //   permissions(newPermissions) {
  //     if (this.isAuthenticated) {
  //       this.updateMenu(this.roles, newPermissions)
  //     }
  //   },
  //   isAuthenticated(newValue) {
  //     if (newValue) {
  //       this.updateMenu(this.roles, this.permissions)
  //     }
  //   }
  // },
  // methods: {
  //   updateMenu(roles, permissions) {
  //     // Dispatch action to filter and update menu
  //     this.$store.dispatch('updateMenu', { roles, permissions })
  //   }
  // },
  // created() {
  //   if (this.isAuthenticated) {
  //     this.updateMenu(this.roles, this.permissions)
  //   }
  // }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
